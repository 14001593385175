<template>
  <div class="page-container">
    <!--筛选-->
    <div class="filter-container clearFix">
      <list-search-filter :search-filter="lists.searchFilter"
                          @clickSearchFilterBtn="query=>ListMethods().clickSearchFilterBtn(query)">
        <!--  操作  -->
        <div slot="right-container">
          <el-button class="el-button" type="primary" size="small"
                     @click="ListMethods().clickOpenExportDialogBtn()">批量导出成绩
          </el-button>
        </div>
      </list-search-filter>
    </div>
    <!--列表-->
    <div class="table-container">
      <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="lists.list" v-loading="lists.loading"
                element-loading-text="加载中" fit
                style="width: 100%;" @sort-change="sort=>ListMethods().sortChange(sort)">
        <el-table-column align="center" label="操作" width="100"
                         class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" size="mini" round
                       @click="ListMethods().clickViewBtn(scope.row,scope.$index)">成绩详情
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="学期">
          <template slot-scope="scope">
            <span>{{ scope.row.semestername }}</span>
          </template>
        </el-table-column>
        <el-table-column label="班级" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.clazzname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验课程" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.experimentname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验开放时间" align="center" width="280" prop="openstarttime" :sortable="'custom'">
          <template slot-scope="scope">
            <span>{{ scope.row.openstarttime | dateFormat }}-{{ scope.row.openendtime | dateFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="实验状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===0" class="status0"><i class="statusDot"></i>未开始</span>
            <span v-if="scope.row.experimentstatus===1" class="status1"><i class="statusDot"></i>进行中</span>
            <span v-if="scope.row.experimentstatus===2" class="status2"><i class="statusDot"></i>已结束</span>
          </template>
        </el-table-column>
        <el-table-column label="班级最高分" width="120" align="center" prop="max" :sortable="true">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===2">
              <!--如果实验已完成，且没有任何人完成实验，分数显示0分-->
              <span v-if="scope.row.max!==null">{{ scope.row.max | numberFormat }}</span>
              <span v-else>0</span>
            </span>
            <span v-else>
              <span v-if="scope.row.max!==null">{{ scope.row.max | numberFormat }}</span>
              <span v-else>--</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="班级最低分" width="120" align="center" prop="min" :sortable="true">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===2">
              <span v-if="scope.row.min!==null">{{ scope.row.min | numberFormat }}</span>
              <span v-else>0</span>
            </span>
            <span v-else>
              <span v-if="scope.row.min!==null">{{ scope.row.min | numberFormat }}</span>
              <span v-else>--</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="班级平均分" width="120" align="center" prop="average" :sortable="true">
          <template slot-scope="scope">
            <span v-if="scope.row.experimentstatus===2">
              <span v-if="scope.row.average!==null">{{ scope.row.average | numberFormat }}</span>
              <span v-else>0</span>
            </span>
            <span v-else>
              <span v-if="scope.row.average!==null">{{ scope.row.average | numberFormat }}</span>
              <span v-else>--</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="完成实验情况" align="center">
          <template slot-scope="scope">
            <span>{{
                scope.row.finishedUserNumber
              }}/{{ scope.row.totalUserNumber }} ({{ (scope.row.finishedUserNumber/scope.row.totalUserNumber * 100).toFixed(0) }}%)</span>
          </template>
        </el-table-column>
        <el-table-column label="填写报告情况" align="center">
          <template slot-scope="scope">
            <span>{{
                scope.row.reportedUserNumber
              }}/{{ scope.row.totalUserNumber }} ({{ (scope.row.reportedUserNumber/scope.row.totalUserNumber * 100).toFixed(0) }}%)</span>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <!--列表分页-->
    <div class="pagination-container">
      <el-pagination background @current-change="(number)=>ListMethods().pageChange(number)"
                     :current-page.sync="lists.pages.number" :page-size.sync="lists.pages.size"
                     layout="total,prev, pager, next,sizes,jumper" :total="lists.pages.totalElements"
                     @size-change="(size)=>ListMethods().pageLimitChange(size)"
                     :page-count="lists.pages.totalPages">
      </el-pagination>
    </div>
    <!--弹窗-成绩导出筛选-->
    <el-dialog
        :close-on-click-modal="false"
        title="批量导出实验成绩"
        :visible.sync="exportDialog.dialog"
        width="660px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <div class="flex flex-around flex-wrap dialog-filter">
          <div class="li">
            <span>学期：</span>
            <el-select v-model="exportDialog.semesterId" class="select" size="small">
              <el-option v-for="(item1,index1) in lists.searchFilter.filter[0].data" :key="index1" :value="item1.value"
                         :label="item1.label"></el-option>
            </el-select>
          </div>
          <div class="li">
            <span>教学班：</span>
            <el-select v-model="exportDialog.clazzId" class="select" size="small">
              <el-option v-for="(item1,index1) in lists.searchFilter.filter[2].data" :key="index1" :value="item1.value"
                         :label="item1.label"></el-option>
            </el-select>
          </div>
          <div class="li">
            <span>实验课程：</span>
            <el-select v-model="exportDialog.experimentId" class="select" size="small">
              <el-option v-for="(item1,index1) in lists.searchFilter.filter[3].data" :key="index1" :value="item1.value"
                         :label="item1.label"></el-option>
            </el-select>
          </div>
          <!--          <div class="li">-->
          <!--            <span>实验状态：</span>-->
          <!--            <el-select v-model="exportDialog.experimentStatus" class="select" size="small">-->
          <!--              <el-option v-for="(item1,index1) in lists.searchFilter.filter[4].data" :key="index1" :value="item1.value"-->
          <!--                         :label="item1.label"></el-option>-->
          <!--            </el-select>-->
          <!--          </div>-->

        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="default" size="small" @click="exportDialog.dialog=false">取消</el-button>
          <el-button type="primary" size="small" @click="ListMethods().clickExportBtn()">导出该老师成绩</el-button>
          <el-button type="default" size="small" @click="ListMethods().clickExportAllBtn()">导出所有老师成绩</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * todo 分数排序现在做的前端排序 等待后端完成排序
 */
import ListSearchFilter from '@/components/list/listSearchFilter'
import {EnumsModel} from '@/model/EnumsModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_err, msg_success} from '@/utils/ele_component'
import {date_format, find_obj_from_arr_by_id, objectToLVArr} from '@/utils/common'
import {mapState} from 'vuex'
import {ClazzModel} from "@/model/exp/ClazzModel";
import {CommonModel} from "@/model/CommonModel";
import {ExperimentModel} from "@/model/exp/ExperimentModel";
import {SemesterModel} from "@/model/exp/SemesterModel";
import {StudentModel} from "@/model/exp/StudentModel";
import {ScoreModel} from "@/model/exp/ScoreModel";
import {dateFormat, numberFormat} from "@/filter/filter";
import {RouterModel} from "@/model/RouterModel";

export default {
  name: 'teacherScore',
  // 组件
  components: {ListSearchFilter},
  // 指令
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    dateFormat: dateFormat,
    numberFormat: numberFormat
  },
  // 计算属性
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      // 外部方法
      date_format: date_format,
      // 枚举
      enums: EnumsModel,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        queryBase: {},
        pages: {
          size: 10
        },
        searchFilter: {
          search: [],
          filter: [
            {
              type: 'select',
              label: '学期',
              key: 'semesterid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '年级',
              key: 'gradeid',
              hidden: true,
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '教学班',
              key: 'clazzid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验课程',
              key: 'experimentid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验状态',
              key: 'experimentalstatus',
              value: '',
              data: objectToLVArr(EnumsModel.experimentStatus, true),
              dataObject: {},
              hidden: true,
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
          ]
        }
      },
      // 实体详情
      entityInfo: {
        title: "新增班级",
        type: "add",
        dialog: false,
        filter: [
          // 学院
          {
            data: [],
            dataObject: {},
            dataOrigin: [],
          },
          // 专业
          {
            data: [],
            dataObject: {},
            dataOrigin: [],
          },
          // 年级
          {
            data: [],
            dataObject: {},
            dataOrigin: [],
          },
        ],
        edit: {},
        // 输入检测
        formRules: {
          'departmentid': {required: true, message: '请选择学院', trigger: 'blur'},
          'majorid': {required: true, message: '请输入专业', trigger: 'blur'},
          'gradeid': {required: true, message: '请选择年级', trigger: 'blur'},
          'name': {required: true, message: '请输入班级名称', trigger: 'blur'},
        },
      },
      exportDialog: {
        dialog: false,
        semesterId: "",
        clazzId: "",
        experimentId: "",
        experimentStatus: ""
      }
    }
  },
  async mounted() {
    // 初始化筛选
    await this.ListMethods().initFilter()
    // 获取列表
    this.ListMethods().getList(1, this.lists.pages.size, this.lists.query)
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 点击批量导出按钮
        clickOpenExportDialogBtn() {
          $this.exportDialog.semesterId = $this.lists.searchFilter.filter[0].value
          $this.exportDialog.clazzId = $this.lists.searchFilter.filter[2].value
          $this.exportDialog.experimentId = $this.lists.searchFilter.filter[3].value
          $this.exportDialog.experimentStatus = $this.lists.searchFilter.filter[4].value
          $this.exportDialog.dialog = true
        },
        // 点击导出按钮
        clickExportBtn() {
          alert("导出时间较长，请耐心等待，不要关闭窗口！")
          ScoreModel.exportMultiple($this.userInfo.userid, $this.exportDialog.semesterId, $this.exportDialog.clazzId, $this.exportDialog.experimentId, $this.exportDialog.experimentStatus)
        },
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.lists.queryBase)
          $this.lists.loading = true;
          [$this.lists.list, $this.lists.pages] = await ScoreModel.getList(page, size, query)
          $this.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.lists.pages.size, $this.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.lists.pages.number, size, $this.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            // 时间按开始和结束排序
            querySort = querySort.replace("opentime,desc", "openendtime,desc")
            querySort = querySort.replace("opentime,asc", "openstarttime,asc")
            $this.$set($this.lists.query, "sort", querySort)
            this.getList(1, $this.lists.pages.size, $this.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type) {
          // 获取学期列表
          if (!type || type === 0) {
            let semesterList = await SemesterModel.getList(0, 0, {
              teacherid: $this.userInfo.userid
            })
            let generateListFilter0 = CommonModel.generateListFilterOptions('name', 'id', semesterList, true)
            $this.$set($this.lists.searchFilter.filter[0], "data", generateListFilter0[0])
            $this.$set($this.lists.searchFilter.filter[0], "dataObject", generateListFilter0[1])
            $this.$set($this.lists.searchFilter.filter[0], "dataOrigin", semesterList)
            // 默认选中当前学期功能，如果没有就选中最近学期 22-04-15 默认显示当前学期实验，提高载入速度 edgar todo 230101暂时关闭
            // let defaultSemesterId = ""
            // for (let i = 0; i < semesterList.length; i++) {
            //   let li = semesterList[i]
            //   if (li.iscurrentsemester) {// 如果有当前时间的学期
            //     defaultSemesterId = li.id
            //   }
            //   if (li.isdefaultselected && !defaultSemesterId) {// 如果有最近的学期
            //     defaultSemesterId = li.id
            //   }
            //   if (!li.isdefaultselected && !defaultSemesterId) {// 如果没有最近的学期
            //     defaultSemesterId = li.id
            //   }
            // }
            // $this.$set($this.lists.searchFilter.filter[0], "value", defaultSemesterId)
            // $this.$set($this.lists, "query", {
            //   semesterid: defaultSemesterId
            // })
          }
          // 获取班级列表
          if (!type || type === 1) {
            let clazzList = (await ClazzModel.getList(1, -1, {}))[0]
            let generateListFilter1 = CommonModel.generateListFilterOptions('clazzName', 'clazzid', clazzList, true)
            $this.$set($this.lists.searchFilter.filter[2], "data", generateListFilter1[0])
            $this.$set($this.lists.searchFilter.filter[2], "dataObject", generateListFilter1[1])
            $this.$set($this.lists.searchFilter.filter[2], "dataOrigin", clazzList)
          }
          // 获取实验列表
          if (!type || type === 2) {
            let experimentList = (await ExperimentModel.getTeacherExperimentList($this.userInfo))
            let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, true)
            $this.$set($this.lists.searchFilter.filter[3], "data", generateListFilter1[0])
            $this.$set($this.lists.searchFilter.filter[3], "dataObject", generateListFilter1[1])
            $this.$set($this.lists.searchFilter.filter[3], "dataOrigin", experimentList)
          }
          $this.$forceUpdate()
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.lists.pages.size, query)
          $this.lists.query = query
        },
        // 点击详情按钮
        clickViewBtn(entity) {
          let scoreInfoTitle = entity.semestername + " " + entity.clazzname + " " + entity.experimentname + " 实验成绩详情"
          RouterModel.routerPush($this, `/teacher/scoreInfo?title=${scoreInfoTitle}&scheduleId=${entity.id}&clazzid=${entity.clazzid}&experimentid=${entity.experimentid}&semesterid=${entity.semesterid}&experimentname=${encodeURI(entity.experimentname)}&clazzname=${encodeURI(entity.clazzname)}`, "成绩管理 / 成绩详情", {}, '4', [
            {name: "成绩管理", path: "/teacher/score", index: '4'},
            {name: "成绩详情"}
          ])
        },
        clickExportAllBtn() {
          let experimentId = $this.exportDialog.experimentId
          let semesterId = $this.exportDialog.semesterId
          if (experimentId && semesterId) {
            alert("导出时间较长，请耐心等待，不要关闭窗口！")
            ScoreModel.exportOneSchoolAllStudentScore($this.userInfo.schoolId, experimentId, semesterId)
          } else {
            msg_err("请先在上方筛选栏中选择要导出的实验课程和学期!")
          }
        }
      }
    },
  }
}
</script>

<style scoped lang="less">
// 实验状态相关
.statusDot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  margin-right: 5px;
  vertical-align: middle;
}

.status0 .statusDot {
  background-color: #bfbfbf;
}

.status1 .statusDot {
  background-color: #0e77d1;
}

.status2 .statusDot {
  background-color: #00a854;
}

.dialog-filter .li {
  width: 300px;
  margin-bottom: 30px;

  span {
    display: inline-block;
    width: 70px;
  }
}
</style>
